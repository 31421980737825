import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { CurrentUser, OauthToken } from '@shared/models';
import { BehaviorSubject, finalize, mergeMap, Observable, of, shareReplay, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserInfo$: Observable<CurrentUser> | null | undefined;
  public isLoggedIn$ = new BehaviorSubject<boolean>(this.hasToken());

  constructor(private httpClient: HttpClient) { }

  public login(code: string, page: string): Observable<boolean> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');

    let params = new HttpParams();
    params = params.append('grant_type', 'authorization_code');
    params = params.append('client_id', env.clientId);
    params = params.append('code', code);
    if (page) {
      params = params.append('redirect_uri', `${env.appUrl}/?page=${page}`);
    } else {
      params = params.append('redirect_uri', `${env.appUrl}/`);
    }

    return this.httpClient.post<OauthToken>(`${env.authUrl}/token`, params,
      { headers })
      .pipe(
        mergeMap((oauth: OauthToken) => {
          this.saveAccessToken(oauth.access_token);
          this.saveRefreshToken(oauth.refresh_token);
          this.isLoggedIn$.next(true);
          return of(true);
        })
      );
  }

  public refreshToken(refreshToken: string): Observable<OauthToken> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');

    let params = new HttpParams();
    params = params.append('grant_type', 'refresh_token');
    params = params.append('client_id', env.clientId);
    params = params.append('refresh_token', refreshToken);

    return this.httpClient.post<OauthToken>(`${env.authUrl}/token`, params,
      { headers })
      .pipe(
        tap((oauth: OauthToken) => {
          this.saveAccessToken(oauth.access_token);
          this.saveRefreshToken(oauth.refresh_token);
          return oauth;
        })
      );
  }

  logout(): Observable<boolean> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');

    let params = new HttpParams();
    params = params.append('client_id', env.clientId);
    params = params.append('refresh_token', `${this.getRefreshToken() || ''}`);

    return this.httpClient.post<boolean>(`${env.authUrl}/logout?redirect_uri=${env.appUrl}/`, params,
      { headers })
      .pipe(mergeMap(() => {
          return of(true);
        }), finalize(() => this.clearToken()));
  }

  public getMe(): Observable<CurrentUser> {
    if (this.currentUserInfo$) {
      return this.currentUserInfo$;
    }
    this.currentUserInfo$ = this.httpClient.get<CurrentUser>(`/api/assets/me`).pipe(shareReplay(1));
    return this.currentUserInfo$;
  }

  public saveAccessToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  public saveRefreshToken(token: string): void {
    localStorage.setItem('refresh_token', token);
  }

  public removeToken(): void {
    localStorage.removeItem('access_token');
  }

  public getAccessToken(): string | null {
    return localStorage.getItem('access_token');
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem('refresh_token');
  }

  public clearToken(): void {
    localStorage.clear();
    this.currentUserInfo$ = null;
    this.isLoggedIn$.next(false);
  }

  public hasToken(): boolean {
    return !!localStorage.getItem('access_token');
  }

}
